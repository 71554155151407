<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-11 px-0 mx-0 "
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-n6 px-0"
        >
          <v-card class="v-card-grey mb-1 px-0 ">
            <v-card-title class="pt-0 pb-0 mb-0 card-title-bloque">
              <div class="w100 d-flex">
                <p class="tit_card pt-2">
                  {{ ('WhatsApp Inbox') }} <span class="subtit_card"> {{ ('Configuración del mensaje automático ausente') }}  </span>
                </p>

                <div class="buttons-container d-flex justify-end flex-grow-1 mr-10">
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        fab
                        small
                        color="white"
                        class="mx-2"
                        elevation="0"
                        v-bind="attrs"
                        :to="{ path: '/design/bandeja-entrada-completa' }"
                        v-on="on"
                      >
                        <v-icon class="btn-submenu-barra">
                          far fa-comments
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Chats</span>
                  </v-tooltip>

                  <v-tooltip
                    bottom
                    class="custom-tooltip"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="white"
                        elevation="0"
                        v-bind="attrs"
                        :to="{ path: '/design/whatsapp-contactos' }"
                        v-on="on"
                      >
                        <v-icon class="btn-submenu-barra">
                          fas fa-id-badge
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Contactos WhatsApp</span>
                  </v-tooltip>


                  <v-tooltip
                    bottom
                    class="custom-tooltip"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="white"
                        elevation="0"
                        v-bind="attrs"
                        :to="{ path: '/design/whatsapp-reportes' }"
                        v-on="on"
                      >
                        <v-icon class="btn-submenu-barra">
                          fas fa-chart-bar
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Reportes</span>
                  </v-tooltip>

                  <v-tooltip
                    bottom

                    class="custom-tooltip"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="white"
                        elevation="0"
                        v-bind="attrs"
                        :to="{ path: '/design/whatsapp-actividad' }"
                        v-on="on"
                      >
                        <v-icon class="btn-submenu-barra ">
                          fas fa-list
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Actividad</span>
                  </v-tooltip>


                  <v-tooltip
                    bottom

                    class="custom-tooltip"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="white"
                        elevation="0"
                        v-bind="attrs"
                        :to="{ path: '/design/whatsapp-configuracion-bandeja-respuestas' }"
                        v-on="on"
                      >
                        <v-icon class="btn-submenu-barra selected">
                          fas fa-cogs
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Configuracion bandeja de entrada</span>
                  </v-tooltip>

                  <!-- <v-divider class="mx-4" vertical  style="max-height: 20px !important;"></v-divider>-->
                </div>

                <!-- <div class="d-flex justify-end">
                  <v-tooltip
                    bottom
                    color="primary"
                    class="custom-tooltip"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="white"
                        elevation="1"
                        v-bind="attrs"

                        v-on="on"
                      >
                        <v-icon color="light-blue darken-3">
                          fas fa-play-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver videotutorial</span>
                  </v-tooltip>
                </div> -->
              </div>
            </v-card-title>


            <v-row class="mb-1 mx-5 px-0 mt-5 ">
              <v-col
                cols="12"
                sm="12"
                md="7"
                lg="9"
                xl="9"
              >
                <v-card class="v-card-grey-mini mb-1 mx-0 px-0 mt-0">
                  <v-card-title class="pt-0 pb-0 mb-0 card-title-bloque">
                    <div class="w100 d-flex">
                      <p class="tit_card_b pt-2">
                        {{ ('Mensaje automático ausente') }}
                      </p>
                    </div>
                  </v-card-title>
                  <v-card-text class="px-4 py-4 my-0">
                    <span>
                      Al habilitar esta opción de mensaje automático ausente, se enviará automáticamente un mensaje de notificación de ausencia a los usuarios que intenten contactarte fuera de tu horario de atención, informándoles sobre tu disponibilidad y asegurando que sus consultas serán atendidas tan pronto como sea posible.
                    </span>

                    <v-switch
                      color="light-blue darken-2"
                      label="Habilitar mensaje automático ausente"
                    />
                    <v-textarea
                      filled
                      class="pb-0 mb-0 "
                      color="light-blue darken-2"
                      background-color="blue-grey lighten-5"
                      :label="$t('Contenido del mensaje automático ausente')"
                      counter="1024"
                      :maxlength="1024"
                    />
                  </v-card-text>

                  <div class="buttons-container d-flex justify-end flex-grow-1 mr-5">
                    <v-btn
                      elevation="0"
                      rounded
                      right
                      small
                      color="light-green darken-3 white--text"
                      class="mt-3 mb-3 float-right"
                    >
                      {{ $t('Guardar') }}
                      <v-icon
                        color="white"
                        class="ml-2"
                      >
                        far fa-save
                      </v-icon>
                    </v-btn>
                  </div>


                  <v-expansion-panels
                    value="0"
                    class="px-5 "
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header background-color="blue-grey lighten-5">
                        Configurar horario de los mensajes automáticos ausentes
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          <v-row class="pt-5">
                            <v-col cols="12">
                              Si no especificas horario se enviarán mensajes automáticos las 24 horas
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                label="Desde"
                                placeholder="Placeholder"
                                outlined
                                type="time"
                                color="light-blue darken-2"
                                background-color="blue-grey lighten-5"
                              />
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                id="toGlobalTime"
                                label="Hasta"
                                name="toGlobalTime"
                                placeholder="Placeholder"
                                outlined
                                type="time"
                                color="light-blue darken-2"
                                background-color="blue-grey lighten-5"
                              />
                            </v-col>
                            <v-col cols="3">
                              <v-btn
                                block
                                large
                                outlined
                                rounded
                                disabled
                                color="v-card-grey darken-1 white--text"
                              >
                                <v-icon left>
                                  far fa-calendar-plus
                                </v-icon>
                                Asignar
                              </v-btn>
                            </v-col>
                            <v-col cols="3">
                              <v-btn

                                id="24hoursBtn"
                                elevation="0"
                                rounded
                                right


                                small
                                block
                                large
                                color="light-blue darken-1 white--text"
                              >
                                <v-icon left>
                                  fas fa-calendar-alt
                                </v-icon>
                                24 horas
                              </v-btn>
                            </v-col>

                            <v-col
                              cols="12"
                              class="d-flex justify-end pt-0 mt-0"
                            >
                              <v-switch
                                color="light-blue darken-2"
                                label="¿Deseas agregar un horario personalizado?"
                              />
                            </v-col>
                          </v-row>


                          <v-divider />


                          <v-row class="pt-5">
                            <v-col cols="3">
                              <v-select
                                :items="['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo']"
                                label="Día de la semana"
                              />
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                type="time"
                                label="Desde"
                              />
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                type="time"
                                label="Hasta"
                              />
                            </v-col>
                            <v-col cols="3">
                              <v-btn
                                block
                                large
                                outlined
                                rounded
                                disabled
                                color="light-blue darken-1 white--text"
                              >
                                <v-icon left>
                                  far fa-calendar-plus
                                </v-icon>
                                Asignar
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12">
                              <v-simple-table>
                                <template #default>
                                  <tbody>
                                    <tr>
                                      <td>lunes</td>
                                      <td>12:12</td>
                                      <td>13:15</td>
                                      <td>
                                        <v-btn
                                          icon
                                          small
                                          color="red"
                                        >
                                          <v-icon>fas fa-times-circle</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>miércoles</td>
                                      <td>12:12</td>
                                      <td>17:12</td>
                                      <td>
                                        <v-btn
                                          icon
                                          small
                                          color="red"
                                        >
                                          <v-icon>fas fa-times-circle</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-col>


              <v-col
                cols="12"
                sm="12"
                md="5"
                lg="3"
                xl="3"
              >
                <v-card class="v-card-blue px-0  v-card v-sheet theme--light elevation-0">
                  <v-list>
                    <v-list-item
                      v-for="(action, index) in actions"
                      :key="index"
                      :class="{ 'selected-item': selectedItem === index }"
                      @click="selectItem(index); $router.push(action.link)"
                    >
                      <v-list-item-avatar
                        class="my-0 me-3"
                        width="20"
                        min-width="20"
                        height="37"
                      >
                        <v-icon class="vlist-menu-superior">
                          {{ action.icon }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-action>
                        <v-list-item-title
                          class="vlist-menu-superior fw300"
                        >
                          {{ action.text }}
                        </v-list-item-title>
                      </v-list-item-action>

                      <div class="border-bottom" />
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
  export default {
    name: 'ProfileMenu',
    components: {},
    data: function () {
      return {
        actions: [
          { link: '/design/whatsapp-configuracion-bandeja-respuestas', text: this.$t('Respuestas rápidas WhatsApp').toString(), icon: 'far fa-comments' },
          { link: '/design/whatsapp-configuracion-bandeja-bienvenida', text: this.$t('Mensaje de Bienvenida').toString(), icon: 'fas fa-plane-departure' },
          { link: '/design/whatsapp-configuracion-bandeja-automatico', text: this.$t('Mensaje de automático ausente').toString(), icon: 'fas fa-robot' },
          { link: '/design/whatsapp-configuracion-bandeja-consumo', text: this.$t('Consumo').toString(), icon: 'fas fa-file-invoice-dollar' },
        ],
        selectedItem: null,
      }
    },
    watch: {},
    mounted () {},
    created () {},
    methods: {
      selectItem (index) {
        this.selectedItem = index
      },
    },
  }
</script>


<style scoped>
.vlist-menu-superior {
  font-size: 14px;
}

.v-list-item {
  height: 10px !important;
}

.selected-item {
  background-color: #eee; /* Cambia este valor para establecer el color de fondo deseado */
}

.v-divider { background-color: rgba(0, 0, 0, 0.06);}
.container-btn-create-channel {
  margin: 0 auto;
  margin-top: 60px;
  width: 100%;
}

.btn-submenu-barra {
  color: #607d8b !important;
}

.btn-submenu-barra.selected {
  color: #039be5 !important;
}

.btn-submenu-barra:hover {
  color: #0093dd !important;
}


</style>

